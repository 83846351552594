import _isDOMElement from "./isDOMElement";
var exports = {};
var isDOMElement = _isDOMElement;
/**
 * Find one or more DOM elements.
 *
 * @param {string} element
 * @returns {Array|null}
 */

exports = function findAllDOMElements(element) {
  if (typeof element === "string") {
    var elements = [].slice.call(document.querySelectorAll(element));
    return elements.length > 0 ? elements : null;
  }

  if (typeof element === "object" && isDOMElement(element)) {
    return [element];
  }
};

export default exports;